import React, { useState, useRef, useEffect } from 'react';
import { FaPlay, FaPause, FaVolumeUp, FaVolumeMute } from 'react-icons/fa';
import './AudioPlayer.css';

const AudioPlayer = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(1);
    const [isMuted, setIsMuted] = useState(false);

    const audioRef = useRef(new Audio('/bgm.mp3')); // 오디오 파일 경로를 지정해주세요

    const togglePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const toggleMute = () => {
        setIsMuted(!isMuted);
        audioRef.current.muted = !isMuted;
    };

    const handleVolumeChange = (e) => {
        const value = parseFloat(e.target.value);
        setVolume(value);
        audioRef.current.volume = value;
    };

    useEffect(() => {
        audioRef.current.volume = volume;
        return () => {
            audioRef.current.pause();
        };
    }, []);

    return (
        <div className="audio-player">
            <button onClick={togglePlayPause} className="play-pause-btn">
                {isPlaying ? <FaPause /> : <FaPlay />}
            </button>
            <button onClick={toggleMute} className="mute-btn">
                {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
            </button>
            <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={handleVolumeChange}
                className="volume-slider"
            />
        </div>
    );
};

export default AudioPlayer;