import * as THREE from 'three'
import { Suspense, useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { Canvas, useFrame, useThree, useLoader } from '@react-three/fiber'
import { useCursor, MeshReflectorMaterial, Image, Text, Environment, Html, ScrollControls, useScroll } from '@react-three/drei'
import { useRoute, useLocation } from 'wouter'
import { easing } from 'maath'
import getUuid from 'uuid-by-string'
import { FaChevronLeft, FaChevronRight, FaBars, FaTimes } from 'react-icons/fa'
import AudioPlayer from './AudioPlayer';
import Footer from './Footer';
import ContactUs from './ContactUs';

const GOLDENRATIO = 1.61803398875
const initialCamPos = new THREE.Vector3(0, 0, 4)
const initialCamRot = new THREE.Quaternion(0, 0, 0, 1)

// 모달 컴포넌트
function Modal({ isOpen, onClose, content }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  if (!isOpen) return null;

  const slides = content.videoId
    ? [{ type: 'video', src: `https://www.youtube.com/embed/${content.videoId}` }, ...(content.images || []).map(img => ({ type: 'image', src: img }))]
    : (content.images || []).map(img => ({ type: 'image', src: img }));

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>X</button>
        <div className="modal-body">
          <div className="slider-container">
            <button className="slider-button left" onClick={prevSlide}>
              <FaChevronLeft />
            </button>
            <div className="slider-content">
              {slides[currentSlide].type === 'video' ? (
                <iframe
                  width="100%"
                  height="100%"
                  src={slides[currentSlide].src}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="YouTube video"
                />
              ) : (
                <img src={slides[currentSlide].src} alt={`Slide ${currentSlide + 1}`} />
              )}
            </div>
            <button className="slider-button right" onClick={nextSlide}>
              <FaChevronRight />
            </button>
          </div>
          <div className="modal-description">
            <h1>{content.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: content.description.replace(/\n/g, '<br>') }} />
          </div>
        </div>
      </div>
    </div>
  );
}

function Navbar({ openContactUs }) {

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogoClick = () => {
    window.location.reload(); // 페이지 새로고침
  };

  const handlePortfolioClick = (e) => {
    e.preventDefault();
    const link = document.createElement('a');
    link.href = '/heart_porfol.pdf';
    link.download = 'HeartVerse_Portfolio.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  return (
    <nav className="navbar">
      <div className="logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
        <img src="/logo.png" alt="Logo" />
      </div>
      <div className={`nav-menu ${isMenuOpen ? 'active' : ''}`}>
        <ul>
          <li><a href="#" onClick={handlePortfolioClick}>Portfolios</a></li>
          <li><button onClick={openContactUs}>Contact Us</button></li>
        </ul>
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </div>
    </nav>
  )
}

function ScrollIndicator() {
  return (
    <div className="scroll-indicator">
      <div className="arrow up">
        <span></span>

      </div>
      <p>Scroll</p>
      <div className="arrow down">
        <span></span>

      </div>
    </div>
  );
}

function CompanyIntro() {
  return (
    <div className="company-intro">
      <h2>We create 3D Web & Virtual rearlity</h2>
      <p>Crafting immersive digital experiences</p>
    </div>
  );
}

export const App = ({ images }) => {
  // 모달 상태 관리
  const [modalState, setModalState] = useState({ isOpen: false, content: { video: '', text: '', title: '' } });
  // 애니메이션 상태 관리
  const [isAnimating, setIsAnimating] = useState(false);

  // 컨택트 유스 모달 상태 관리
  const [isContactUsOpen, setIsContactUsOpen] = useState(false);

  const openContactUs = () => setIsContactUsOpen(true);
  const closeContactUs = () => setIsContactUsOpen(false);


  const [, setLocation] = useLocation();

  // Camera target state variables
  const [cameraTargetPosition, setCameraTargetPosition] = useState(initialCamPos.clone());
  const [cameraTargetQuaternion, setCameraTargetQuaternion] = useState(initialCamRot.clone());

  // Frame click handler
  const handleFrameClick = useCallback((content, targetPosition, targetQuaternion) => {
    setIsAnimating(true);
    setCameraTargetPosition(targetPosition);
    setCameraTargetQuaternion(targetQuaternion);

    // Open modal after camera movement
    setTimeout(() => {
      setModalState({ isOpen: true, content });
      setIsAnimating(false);
    }, 1000);
  }, []);

  // Modal close handler
  const closeModal = useCallback(() => {
    setIsAnimating(true);
    setModalState(prev => ({ ...prev, isOpen: false }));

    // Reset camera to initial position
    setCameraTargetPosition(initialCamPos.clone());
    setCameraTargetQuaternion(initialCamRot.clone());

    // Reset the route and selected frame
    setLocation('/'); // Reset the route to '/'
    setTimeout(() => {
      setIsAnimating(false);
    }, 1000);
  }, [setLocation]);

  return (
    <>
      <Navbar openContactUs={openContactUs} />
      <ContactUs isOpen={isContactUsOpen} onClose={closeContactUs} />
      <AudioPlayer />
      <Canvas style={{ height: 'calc(100vh - 150px)' }} dpr={[1, 1.5]} camera={{ fov: 70, position: [0, 10, 10] }}>
        <color attach="background" args={['#191920']} />
        <fog attach="fog" args={['#191920', 0, 15]} />
        <Environment preset="forest" />
        <ScrollControls pages={images.length + 1} damping={0.05} enabled={!modalState.isOpen && !isAnimating}>
          <group position={[0, -1, 0]}>
            <Frames images={images}
              onFrameClick={handleFrameClick}
              isModalOpen={modalState.isOpen}
              isAnimating={isAnimating}
              cameraTargetPosition={cameraTargetPosition}
              cameraTargetQuaternion={cameraTargetQuaternion}
              setCameraTargetPosition={setCameraTargetPosition} // Pass the setter function
              setCameraTargetQuaternion={setCameraTargetQuaternion} // Pass the setter function
              initialCamPos={initialCamPos} // Pass initial camera position
              initialCamRot={initialCamRot} // Pass initial camera rotation             
            />
            <mesh rotation={[-Math.PI / 2, 0, 0]}>
              <planeGeometry args={[50, 50]} />
              <MeshReflectorMaterial
                blur={[300, 100]}
                resolution={2048}
                mixBlur={1}
                mixStrength={80}
                roughness={1}
                depthScale={1.2}
                minDepthThreshold={0.4}
                maxDepthThreshold={1.4}
                color="#050505"
                metalness={0.5}
              />
            </mesh>
          </group>
        </ScrollControls>
        <CameraPointerController enabled={!modalState.isOpen && !isAnimating} />
      </Canvas>
      <Modal {...modalState} onClose={closeModal} />
      <CompanyIntro />
      <ScrollIndicator />
      <Footer />
    </>
  )
}



function Frames({ images,
  onFrameClick,
  isModalOpen,
  isAnimating,
  cameraTargetPosition,
  cameraTargetQuaternion, setCameraTargetPosition, setCameraTargetQuaternion,
  initialCamPos,
  initialCamRot,
}) {
  const ref = useRef()
  const clicked = useRef()
  const [, params] = useRoute('/item/:id')
  const [, setLocation] = useLocation()
  const [selectedFrame, setSelectedFrame] = useState(null)

  const scroll = useScroll()
  const { viewport, camera } = useThree()

  // 랜덤 위치 생성 함수
  const getRandomPosition = () => {
    const width = viewport.width
    const height = viewport.height

    const x = THREE.MathUtils.randFloatSpread(width * 0.4)
    const y = THREE.MathUtils.randFloat(0, height * 0.4) + 0.5
    const z = THREE.MathUtils.randFloatSpread(5)

    return [x, y, z]
  }

  // 랜덤 위치 및 회전 계산
  const randomPositions = useMemo(() => images.map(() => ({
    position: getRandomPosition(),
    rotation: [Math.random() * Math.PI * 0.1, Math.random() * Math.PI * 0.1, 0],
    floatOffset: Math.random() * Math.PI * 2
  })), [images, viewport])

  useEffect(() => {
    // When URL params change, update the selected frame and camera targets
    clicked.current = ref.current.getObjectByName(params?.id);
    if (clicked.current && !isModalOpen && !isAnimating) {
      clicked.current.parent.updateWorldMatrix(true, true);
      const targetPosition = new THREE.Vector3();
      const targetQuaternion = new THREE.Quaternion();
      clicked.current.parent.localToWorld(targetPosition.set(0, GOLDENRATIO / 2, 1.25));
      clicked.current.parent.getWorldQuaternion(targetQuaternion);
      setSelectedFrame(clicked.current);

      // Update camera targets
      setCameraTargetPosition(targetPosition);
      setCameraTargetQuaternion(targetQuaternion);
    } else if (!selectedFrame && !isModalOpen && !isAnimating) {
      // Reset to initial position if no frame is selected
      setCameraTargetPosition(initialCamPos.clone());
      setCameraTargetQuaternion(initialCamRot.clone());
    }
  }, [params, isModalOpen, isAnimating]);

  useFrame((state, dt) => {
    const scrollProgress = scroll.offset

    // Smoothly move camera towards the target position and rotation
    easing.damp3(state.camera.position, cameraTargetPosition, 0.4, dt);
    easing.dampQ(state.camera.quaternion, cameraTargetQuaternion, 0.4, dt);

    const initialToRandomProgress = Math.min(Math.max((scrollProgress - 0.1) / 0.1, 0), 1)
    const randomToAlignedProgress = Math.min(Math.max((scrollProgress - 0.2) / 0.1, 0), 1)


    const frameSpacing = 1.8 //프레임 사이 간격
    const totalHeight = (images.length + 3) * frameSpacing
    const startY = 0.1 // 카메라 높이(2)보다 약간 위에서 시작

    ref.current.children.forEach((child, index) => {
      const initialPosition = images[index].position
      const initialRotation = images[index].rotation
      const { position: randomPosition, rotation: randomRotation, floatOffset } = randomPositions[index]
      const time = state.clock.elapsedTime

      // 플로팅 효과
      const floatY = Math.sin(time + floatOffset) * 0.1

      // 위치 계산
      const initialPos = new THREE.Vector3(...initialPosition)
      const randomPos = new THREE.Vector3(...randomPosition).add(new THREE.Vector3(0, floatY, 0))

      // aligned 상태에서의 세로 스크롤 위치 계산
      const alignedZpos = 2 // aligned 일때 프레임 그룹의 Z 포지션
      const alignedScrollOffset = Math.max(scrollProgress - 0.3, 0) * totalHeight
      const alignedPos = new THREE.Vector3(0, startY - index * frameSpacing + alignedScrollOffset + floatY, alignedZpos)

      const pos1 = initialPos.lerp(randomPos, initialToRandomProgress)
      const pos2 = randomPos.lerp(alignedPos, randomToAlignedProgress)
      const finalPos = pos1.lerp(pos2, randomToAlignedProgress)

      // 회전 계산
      const initialRot = new THREE.Euler(...initialRotation)
      const randomRot = new THREE.Euler(...randomRotation)
      const alignedRot = new THREE.Euler(
        Math.sin(time + floatOffset) * 0.02,
        Math.cos(time + floatOffset) * 0.02,
        0
      )

      const rot1 = new THREE.Euler().setFromQuaternion(
        new THREE.Quaternion().setFromEuler(initialRot).slerp(
          new THREE.Quaternion().setFromEuler(randomRot),
          initialToRandomProgress
        )
      )
      const rot2 = new THREE.Euler().setFromQuaternion(
        new THREE.Quaternion().setFromEuler(randomRot).slerp(
          new THREE.Quaternion().setFromEuler(alignedRot),
          randomToAlignedProgress
        )
      )
      const finalRot = new THREE.Euler().setFromQuaternion(
        new THREE.Quaternion().setFromEuler(rot1).slerp(
          new THREE.Quaternion().setFromEuler(rot2),
          randomToAlignedProgress
        )
      )

      // 최종 위치와 회전 적용
      easing.damp3(child.position, finalPos, 0.2, dt)
      easing.damp3(child.rotation, [finalRot.x, finalRot.y, finalRot.z], 0.2, dt)

      // 스케일 계산 (aligned 상태에서만 적용)
      const distanceFromCenter = Math.abs(finalPos.y - startY)
      const scale = 1 - Math.min(distanceFromCenter / (totalHeight / 2), 0.4) * randomToAlignedProgress
      easing.damp3(child.scale, [scale, scale, scale], 0.2, dt)
    })
  })


  return (
    <group
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        if (!isModalOpen && !isAnimating) {
          const clickedImage = images.find(img => getUuid(img.url) === e.object.name);
          const newLocation = clicked.current === e.object ? '/' : '/item/' + e.object.name;
          setLocation(newLocation);
          setSelectedFrame(e.object);

          // Calculate camera targets based on clicked frame
          const targetPosition = new THREE.Vector3();
          const targetQuaternion = new THREE.Quaternion();
          e.object.parent.updateWorldMatrix(true, true);
          e.object.parent.localToWorld(targetPosition.set(0, GOLDENRATIO / 2, 1.25));
          e.object.parent.getWorldQuaternion(targetQuaternion);

          // Use the setter functions to update camera targets
          setCameraTargetPosition(targetPosition);
          setCameraTargetQuaternion(targetQuaternion);

          onFrameClick( //프레임을 클릭하면 index.js에서 정의된 images 배열의 값을 가져오는 함수
            {
              videoId: clickedImage.videoId || '',
              description: clickedImage.description || `Description for frame ${e.object.name}`,
              title: clickedImage.title || `Title for frame ${e.object.name}`,
              images: clickedImage.images || []
            },
            targetPosition,
            targetQuaternion
          );
        }
      }}

      onPointerMissed={() => {
        if (!isModalOpen && !isAnimating) {
          setLocation('/');
          setSelectedFrame(null);
          clicked.current = null; // Clear the clicked reference

          // Reset camera targets to initial position and rotation
          setCameraTargetPosition(initialCamPos.clone());
          setCameraTargetQuaternion(initialCamRot.clone());
        }
      }}
    >
      {images.map((props) => <Frame key={props.url} {...props} />)}
    </group>
  )
}

function Frame({ url, c = new THREE.Color(), ...props }) {
  const group = useRef()
  const image = useRef()
  const frame = useRef()
  const filter = useRef()
  const [, params] = useRoute('/item/:id')
  const [hovered, hover] = useState(false)
  const [rnd] = useState(() => Math.random())
  const name = getUuid(url)
  const isActive = params?.id === name
  useCursor(hovered)
  useFrame((state, dt) => {
    image.current.material.zoom = 1.5 + Math.sin(rnd * 10000 + state.clock.elapsedTime / 3) / 2
    easing.dampC(frame.current.material.color, hovered ? '#FFF996' : 'white', 0.1, dt)

    // 필터의 scale을 이미지의 scale과 동일하게 유지
    if (filter.current && image.current) {
      filter.current.scale.copy(image.current.scale)
    }
  })
  return (
    <group {...props}>
      <mesh
        name={name}
        onPointerOver={(e) => (e.stopPropagation(), hover(true))}
        onPointerOut={() => hover(false)}
        scale={[1, GOLDENRATIO, 0.05]}
        position={[0, GOLDENRATIO / 2, 0]}>
        <boxGeometry />
        <meshStandardMaterial color="#151515" metalness={0.5} roughness={0.5} envMapIntensity={2} />
        <mesh ref={frame} raycast={() => null} scale={[0.9, 0.93, 0.9]} position={[0, 0, 0.2]}>
          <boxGeometry />
          <meshBasicMaterial toneMapped={false} fog={false} />
        </mesh>
        <Image raycast={() => null} ref={image} position={[0, 0, 0.7]} scale={[0.85, 0.90, 0]} url={url} />
        <mesh
          ref={filter}
          position={[0, 0, 0.71]}
          raycast={() => null}
          onPointerOver={(e) => e.stopPropagation()}
          onPointerOut={(e) => e.stopPropagation()}
        >
          <planeGeometry scale={[0.9, 0.93, 0.9]} />
          <meshBasicMaterial
            color="#000000"
            opacity={0.3}
            transparent
            blending={THREE.NormalBlending}
            depthTest={true}
            depthWrite={false}
          />
        </mesh>
      </mesh>
      <Text maxWidth={0.1} anchorX="left" anchorY="top" position={[0.55, GOLDENRATIO, 0]} fontSize={0.025}>
        {name.split('-').join(' ')}
      </Text>
    </group >
  )
}

function CameraPointerController({ enabled }) {
  const { camera } = useThree()
  const targetRotation = useRef(new THREE.Euler(0, 0, 0, 'XYZ'))
  const currentRotation = useRef(new THREE.Euler(0, 0, 0, 'XYZ'))
  const pointer = useRef({ x: 0, y: 0 })

  const handlePointerMove = (event) => {
    pointer.current.x = (event.clientX / window.innerWidth) * 2 - 1
    pointer.current.y = -(event.clientY / window.innerHeight) * 2 + 1
  }

  useEffect(() => {
    window.addEventListener('pointermove', handlePointerMove)
    return () => {
      window.removeEventListener('pointermove', handlePointerMove)
    }
  }, [])

  useFrame((state, delta) => {
    if (!enabled) return;

    const rotationFactor = 0.006

    targetRotation.current.x = pointer.current.y * rotationFactor
    targetRotation.current.y = pointer.current.x * -rotationFactor

    currentRotation.current.x = THREE.MathUtils.lerp(currentRotation.current.x, targetRotation.current.x, 0.1)
    currentRotation.current.y = THREE.MathUtils.lerp(currentRotation.current.y, targetRotation.current.y, 0.1)

    const euler = currentRotation.current
    const quat = new THREE.Quaternion().setFromEuler(euler)

    camera.quaternion.copy(quat.multiply(camera.quaternion.clone()))
  })

  return null
}