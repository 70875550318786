import { createRoot } from 'react-dom/client'
import './styles.css'
import { App } from './App'

const getThumbImagePath = (index) => `/images/thumb_${(index + 1).toString().padStart(2, '0')}.png`

// 프로젝트 이미지를 동적으로 가져오는 함수
function getProjectImages(projectNumber, imageCount = 4) {
  return Array.from({ length: imageCount }, (_, i) =>
    `/images/projects/pr_${String(projectNumber).padStart(2, '0')}_${String(i + 1).padStart(2, '0')}.jpg`
  );
}

const index = 0;
const images = [
  // Front
  {
    position: [0, 0.2, 1.5], rotation: [0, 0, 0], url: getThumbImagePath(0), index: 1,
    videoId: "D6Pcc5KhEeM",
    title: "3D 컨피규레이터",
    description: "언리얼5 로 제작한 전기차 충전기 컨피규레이터 \n 다양한 옵션을 시뮬레이션 해볼 수 있는 기능 \n \n <h3>주요 기능</h3>  언리얼 5 프로젝트 \n Stl 파일 최적화 \n 색상, 재질, 조명, 카메라 무빙 조정 가능\n \n <h3>기술 스택</h3> Unreal5, Figma, Blender",
    images: getProjectImages(1)
  },

  // Left
  {
    position: [-2.55, 0.2, 2.75], rotation: [0, Math.PI / 2.5, 0], url: getThumbImagePath(1), index: 2,
    videoId: "WUTOw4pv9Ww",
    title: "3D웹 메타버스 플랫폼",
    description: "Three.js와 WebRTC, React, AWS로 구성한 웹 3D메타버스 플랫폼 \n 캐릭터와 화상미팅이 가능한 웹 메타버스 공간 \n \n <h3>주요 기능</h3> 3D캐릭터 생성/꾸미기 \n 1:1, 1:n 화상미팅 기능 \n 회의실 예약, 회의실 잠금 기능 \n 화면 공유 및 발표 기능\n \n <h3>기술 스택</h3> Three.js Babylon.js, WebRTC, AWS, Figma, React, BLE",
    images: getProjectImages(2)
  },

  {
    position: [-2.15, 0.2, 1.5], rotation: [0, Math.PI / 2.5, 0], url: getThumbImagePath(2), index: 3,
    videoId: "peBIZQ_2g70",
    title: "LG에너지솔루션 AR 앱",
    description: "유니티 ARFoundation으로 구현한 배터리 증강 앱 \n \n <h3>주요 기능</h3> 유니티 \n 배터리 3D모델 증강 \n 배터리 3D애니메이션\n \n <h3>기술 스택</h3> Unity, AWS, Figma, Blender",
    images: getProjectImages(3)
  },
  {
    position: [-1.75, 0.2, 0.25], rotation: [0, Math.PI / 2.5, 0], url: getThumbImagePath(3), index: 4,
    videoId: "",
    title: "3D 가상 스튜디오",
    description: "웹과 모바일에서도 품질 차이를 줄인 3D웹 가상 스튜디오 \n \n <h3>주요 기능</h3> 3D가상 스튜디오 \n 웹, 모바일 모두 지원 \n WebGl, WebGpu 지원 \n \n <h3>기술 스택</h3> Three.js, WebGPU, Blender, AWS",
    images: getProjectImages(4)
  },

  // Back
  {
    position: [-0.8, 0.2, -0.6], rotation: [0, 0, 0], url: getThumbImagePath(4), index: 5,
    videoId: "sPICy74up94",
    title: "디지털 트윈",
    description: "실제 도면을 기반으로 제작된 3D 스튜디오 디지털 트윈 \n \n <h3>주요 기능</h3> 블렌더 모델링 \n언리얼5 렌더링 \n 언리얼5 카메라 애니메이션 \n \n <h3>기술 스택</h3> Blender, Unreal5",
    images: getProjectImages(5)
  },
  {
    position: [0.8, 0.2, -0.6], rotation: [0, 0, 0], url: getThumbImagePath(5), index: 6,
    videoId: "EFGyYP8K_xs",
    title: "3D웹 블록 조립 서비스",
    description: "Three.js를 이용하여 개발한 3D 블록 조립 기능을 중심으로 다양한 방식을 통해 사용자가 원하는 형태를 구현하는 서비스. \n \n <h3>주요 기능</h3> 3D블록 조립 \n 로그인, 마이페이지, 워크스페이스 \n 파일 다운로드 기능, 결제 및 구독 기능\n \n <h3>기술 스택</h3> Three.js, AWS, Figma, Blender",
    images: getProjectImages(6)
  },

  // Right
  {
    position: [1.75, 0.2, 0.25], rotation: [0, -Math.PI / 2.5, 0], url: getThumbImagePath(6), index: 7,
    videoId: "Sr9pegShJXI",
    title: "뇌파측정기 연동 콘텐츠 앱",
    description: "블루투스로 연결된 뇌파측정기로 컨트롤하는 뇌파 훈련 콘텐츠 플랫폼 \n Flutter로 개발된 하이브리드 앱에 유니티 빌드를 연동한 플랫폼 구축 \n 관리자 페이지를 통해 유니티 빌드를 관리하는 기능 \n  \n <h3>주요 기능</h3> SNS로그인 \n 2D, 3D게임 콘텐츠 25종 \n 점수에 따른 랭킹 및 리더보드 시스템 \n 관리자 시스템 \n \n <h3>기술 스택</h3> Flutter, Unity, AWS, Figma, React, BLE",
    images: getProjectImages(7)
  },
  {
    position: [2.15, 0.2, 1.5], rotation: [0, -Math.PI / 2.5, 0], url: getThumbImagePath(7), index: 8,
    videoId: "",
    title: "AR Try On 서비스",
    description: "머신러닝을 활용한 사물 인식 기술과 AR 기술을 결합하여 신발, 핸드백, 의류 등의 패션 및 e-커머스 앱에 결합 \n \n <h3>주요 기능</h3> AI 신체 인식 기능 \n 패션 제품 증강기능 \n 패션 제품 구매 페이지로 이동\n \n <h3>기술 스택</h3> ARFoundation, Mideapipe, AWS",
    images: getProjectImages(8)
  },
  {
    position: [2.55, 0.2, 2.75], rotation: [0, -Math.PI / 2.5, 0], url: getThumbImagePath(8), index: 9,
    videoId: "QtN3puoH154",
    title: "골프스윙 분석 시스템",
    description: "디지털 트윈 기술을 기반으로 다양한 기술을 활용하여 골프스윙모션을 분석하고, 3D 아바타에 실시간으로 연동하는 서비스 개발 \n \n <h3>주요 기능</h3> AI 기반 모션 분석 알고리즘 개발 \n 3D 아바타에 실시간 연동 \n 골프웨어, 장비 추천 알고리즘 개발 \n 실시간 체형 분석 \n \n <h3>기술 스택</h3> Electron, Unreal, Tensorflow, Figma, AWS ",
    images: getProjectImages(9)
  }
]

createRoot(document.getElementById('root')).render(<App images={images} />)
